import { createRouter, createWebHistory } from 'vue-router';

import Home from '../views/index.vue';
import store from '../store';
import decodeJWT from '@/composables/decodeJWT.js';

const routes = [
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/auth/login.vue'),
        meta: { layout: 'auth' },
    },
    //dashboard
    {
        path: '/',
        name: 'Home',
        component: () => import('../views/index.vue'),
        meta: { requiresAuth: true },
    },
    // Atividades ----------------------
    {
        path: '/atividades',
        name: 'Atividades',
        component: () => import('../views/atividades/atividadesIndex.vue'),
        meta: { layout: 'app', requiresAuth: true },
    },
    {
        path: '/atividades/cadastrar-atividades',
        name: 'AtividadesCadastrar',
        component: () => import('../views/atividades/atividadesCadastrar.vue'),
        meta: { layout: 'app', requiresAuth: true },
    },
    {
        path: '/atividades/historico',
        name: 'Historico',
        component: () => import('../views/atividades/atividadesHistorico.vue'),
        meta: { layout: 'app', requiresAuth: true },
    },
    {
        path: '/atividades/gerenciar',
        name: 'AtividadesGerenciar',
        component: () => import('../views/atividades/atividadesGerenciar.vue'),
        meta: { layout: 'app', requiresAuth: true, roles: ['Admin','ResponsavelFinanceiro','ResponsavelFiscal','ResponsavelContabil','ResponsavelDepartamento','ResponsavelTi','ResponsavelAuditoria','ResponsavelAdministrativo','ResponsavelDepartamento','ResponsavelMarketing','ResponsavelComercial','ResponsavelProcessos'] },
    },
    {
        path: '/atividades/lancamentos',
        name: 'AtividadesLancamentos',
        component: () => import('../views/atividades/atividadeslancamentos.vue'),
        meta: { layout: 'app', requiresAuth: true, roles: ['Admin'] },
    },
    // Usuários ----------------------
    {
        path: '/usuarios/cadastrar',
        name: 'UsuariosCadastrar',
        component: () => import('../views/usuarios/usuariosCadastrar.vue'),
        meta: { layout: 'app', requiresAuth: true, roles: ['Admin'] },
    },
    {
        path: '/usuarios/listar',
        name: 'UsuariosListar',
        component: () => import('../views/usuarios/usuariosListar.vue'),
        meta: { layout: 'app', requiresAuth: true, roles: ['Admin'] },
    },
    // Clientes ----------------------
    {
        path: '/clientes/cadastrar',
        name: 'ClientesCadastrar',
        component: () => import('../views/clientes/clientesCadastrar.vue'),
        meta: { layout: 'app', requiresAuth: true, roles: ['Admin'] },
    },
    {
        path: '/clientes/listar',
        name: 'ClientesListar',
        component: () => import('../views/clientes/clientesListar.vue'),
        meta: { layout: 'app', requiresAuth: true, roles: ['Admin'] },
    },
    // Relatórios ----------------------
    {
        path: '/relatorios',
        name: 'Relatorios',
        component: () => import('../views/relatorios/relatoriosIndex.vue'),
        meta: { layout: 'app', requiresAuth: true, roles: ['Admin','ResponsavelFinanceiro','ResponsavelFiscal','ResponsavelContabil','ResponsavelDepartamento','ResponsavelTi','ResponsavelAuditoria','ResponsavelAdministrativo','ResponsavelDepartamento','ResponsavelMarketing','ResponsavelComercial','ResponsavelProcessos'] },
    },
    {
        path: '/relatorios/historico-atividade',
        name: 'RelatoriosHistoricoAtividade',
        component: () => import('../views/relatorios/RelatoriosHistoricoAtividade.vue'),
        meta: { layout: 'app', requiresAuth: true, roles: ['Admin','ResponsavelFinanceiro','ResponsavelFiscal','ResponsavelContabil','ResponsavelDepartamento','ResponsavelTi','ResponsavelAuditoria','ResponsavelAdministrativo','ResponsavelDepartamento','ResponsavelMarketing','ResponsavelComercial','ResponsavelProcessos'] },
    },
    {
        path: '/relatorios/historico-dia-dia',
        name: 'RelatoriosHistoricoDiaDia',
        component: () => import('../views/relatorios/RelatoriosHistoricoDiaDia.vue'),
        meta: { layout: 'app', requiresAuth: true, roles: ['Admin','ResponsavelFinanceiro','ResponsavelFiscal','ResponsavelContabil','ResponsavelDepartamento','ResponsavelTi','ResponsavelAuditoria','ResponsavelAdministrativo','ResponsavelDepartamento','ResponsavelMarketing','ResponsavelComercial','ResponsavelProcessos'] },
    },
    {
        path: '/relatorios/horas-por-clientes',
        name: 'RelatoriosHorasClientes',
        component: () => import('../views/relatorios/RelatoriosHorasClientes.vue'),
        meta: { layout: 'app', requiresAuth: true, roles: ['Admin','ResponsavelFinanceiro','ResponsavelFiscal','ResponsavelContabil','ResponsavelDepartamento','ResponsavelTi','ResponsavelAuditoria','ResponsavelAdministrativo','ResponsavelDepartamento','ResponsavelMarketing','ResponsavelComercial','ResponsavelProcessos'] },
    }, 
    // perfil ----------------------
    {
        path: '/perfil',
        name: 'Perfil',
        component: () => import('../views/users/profile.vue'),
        meta: { layout: 'app', requiresAuth: true},
    },
    {
        path: '/master',
        name: 'Master',
        component: () => import('../views/master/indexMaster.vue'),
        meta: { layout: 'app', requiresAuth: true},
    },
];

const router = createRouter({
    history: createWebHistory(),
    linkExactActiveClass: 'active',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { left: 0, top: 0 };
        }
    },
});

router.beforeEach((to, from, next) => {
    if (to.meta && to.meta.layout && to.meta.layout === 'auth') {
        store.commit('setLayout', 'auth');
    } else {
        store.commit('setLayout', 'app');
    }

    if (to.meta.requiresAuth) {
        const JWT = localStorage.getItem('token');
        if (JWT) {
            const userRole = decodeJWT()['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
            if (to.meta.roles) {
                if (to.meta.roles.includes(userRole)) {
                    next();
                } else {
                    next('/');
                }
            } else {
                next();
            }
        } else {
            next('/login');
        }
    } else {
        next();
    }
});

export default router;
