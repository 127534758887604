const enumSetores = (setorId) => {
    const id = Number(setorId);

    switch (id) {
        case 1:
            return 'TI';
        case 2:
            return 'Departamento Pessoal';
        case 3:
            return 'Auditoria';
        case 4:
            return 'Administrativo';
        case 5:
            return 'Contabil';
        case 6:
            return 'Financeiro';
        case 7:
            return 'Fiscal';
        case 8:
            return 'Marketing';
        case 9:
            return 'Comercial';
        case 10:
            return 'Processos';
        default:
            return 'Setor Desconhecido';
    }
};

export default enumSetores;
